.video-container {
  position: relative;
}

.video {
  width: 100%;
  height: 100%;
}

.video video::-webkit-media-controls-enclosure {
  display: none !important;
}

.video video::-webkit-media-controls {
  display: none !important;
}

.video video::-moz-media-controls {
  display: none !important;
}

/**/
.custom-controls {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.9);
  padding: 4px 10px 4px 10px;
  border-radius: 0px 0px 5px 5px;
  position: relative;
  width: 100%;
  z-index: 10;
}

.play-pause-btn {
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  outline: none;
}

.play-pause-btn,
.play-pause-btn {
  display: inline-block;
}

.time-controls {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  color: white;
  font-family: 'Arial', sans-serif;
  font-size: 14px;
}

.current-time,
.duration {
  margin: 0 10px;
}

.seek-bar {
  flex-grow: 1;
  margin-left: 10px;
  background-color: transparent;
  margin-bottom: 2px;
  appearance: none;
  cursor: pointer;
}

.seek-bar::-webkit-slider-runnable-track {
  width: 100%;
  height: 8px;
  cursor: pointer;
  background: #888;
  border-radius: 5px;
}

.seek-bar::-webkit-slider-thumb {
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: white;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -4px;
}

.seek-bar:focus {
  outline: none;
}

.seek-bar:focus::-webkit-slider-runnable-track {
  background: #888;
}

.seek-bar::-moz-range-track {
  width: 100%;
  height: 8px;
  cursor: pointer;
  background: #888;
  border-radius: 5px;
}

.seek-bar::-moz-range-thumb {
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: white;
  cursor: pointer;
}

.seek-bar::-ms-track {
  width: 100%;
  height: 8px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  color: transparent;
}

.seek-bar::-ms-fill-lower {
  background: #777;
  border-radius: 5px;
}

.seek-bar::-ms-fill-upper {
  background: #888;
  border-radius: 5px;
}

.seek-bar::-ms-thumb {
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: white;
  cursor: pointer;
}

.seek-bar::-ms-tooltip {
  display: none;
}
/**/

.watermark {
  position: absolute;
  /* top: 50%; */
  top: 10%;
  /* left: 50%; */
  left: 35%;
  transform: translate(-50%, -50%);
  z-index: 200;
  color: white;
}
.watermark img {
  width: 400px;
}

.volume-controls {
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.volume-bar {
  width: 100px;
  appearance: none;
  cursor: pointer;
  background-color: transparent;
}

.volume-bar::-webkit-slider-runnable-track {
  width: 100%;
  height: 8px;
  cursor: pointer;
  background: #888;
  border-radius: 5px;
}

.volume-bar::-webkit-slider-thumb {
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: white;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -4px;
}

.volume-bar:focus {
  outline: none;
}

.volume-bar:focus::-webkit-slider-runnable-track {
  background: #888;
}

.volume-bar::-moz-range-track {
  width: 100%;
  height: 8px;
  cursor: pointer;
  background: #888;
  border-radius: 5px;
}

.volume-bar::-moz-range-thumb {
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: white;
  cursor: pointer;
}

.volume-bar::-ms-track {
  width: 100%;
  height: 8px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  color: transparent;
}

.volume-bar::-ms-fill-lower {
  background: #777;
  border-radius: 5px;
}

.volume-bar::-ms-fill-upper {
  background: #888;
  border-radius: 5px;
}

.volume-bar::-ms-thumb {
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: white;
  cursor: pointer;
}

.volume-bar::-ms-tooltip {
  display: none;
}
